<template>
  <div id="information">
    <div class="top"></div>
    <div class="news wrapper">
      <div class="title">
        <router-link to="/dash">首页<span v-html="'&nbsp'"></span></router-link>
        <router-link to="/information"
          >-<span v-html="'&nbsp'"></span>资讯<span v-html="'&nbsp'"></span
        ></router-link>
        <a href="javascript:void(0)"
          >-<span v-html="'&nbsp'"></span
          >国务院办公厅印发《关于加快发展保障性租赁住房的意见》</a
        >
      </div>
      <div class="content">
        <el-row>
          <el-col :span="18" class="left">
            <div class="news_title">
              <div class="newsDate">
                <div class="year">2021-07</div>
                <div class="day">06</div>
              </div>
              <div class="newsText">
                国务院办公厅印发《关于加快发展保障性租赁住房的意见》
              </div>
            </div>
            <div class="news_content">
              <p>
                新华社北京7月2日电
                国务院办公厅日前印发《关于加快发展保障性租赁住房的意见》（以下简称《意见》），明确保障性租赁住房基础制度和支持政策。
              </p>
              <br />
              <p>
                《意见》指出，以习近平新时代中国特色社会主义思想为指导，坚持房子是用来住的、不是用来炒的定位，突出住房的民生属性，扩大保障性租赁住房供给，缓解住房租赁市场结构性供给不足，推动建立多主体供给、多渠道保障、租购并举的住房制度，推进以人为核心的新型城镇化，促进实现全体人民住有所居。
              </p>
              <br />
              <p>
                《意见》明确，保障性租赁住房主要解决符合条件的新市民、青年人等群体的住房困难问题，以建筑面积不超过70平方米的小户型为主，租金低于同地段同品质市场租赁住房租金；由政府给予政策支持，充分发挥市场机制作用，引导多主体投资、多渠道供给，主要利用存量土地和房屋建设，适当利用新供应国有建设用地建设。城市人民政府要坚持供需匹配，科学确定“十四五”保障性租赁住房建设目标和政策措施，制定年度建设计划，并向社会公布；加强对保障性租赁住房建设、出租和运营管理的全过程监督，强化工程质量安全监管。城市人民政府对本地区发展保障性租赁住房负主体责任，省级人民政府负总责。
              </p>
              <br />
              <p>
                《意见》提出以下支持政策。一是进一步完善土地支持政策。人口净流入的大城市和省级人民政府确定的城市，可探索利用集体经营性建设用地建设保障性租赁住房；允许利用企事业单位自有土地建设保障性租赁住房，变更土地用途，不补缴土地价款；可将产业园区配套用地面积占比上限由7%提高到15%，提高部分主要用于建设宿舍型保障性租赁住房；保障性租赁住房用地可采取出让、租赁或划拨等方式供应。允许将非居住存量房屋改建为保障性租赁住房，不变更土地使用性质，不补缴土地价款。二是简化审批流程。利用非居住存量土地和非居住存量房屋建设保障性租赁住房，可由市县人民政府组织有关部门联合审查建设方案，出具保障性租赁住房项目认定书后，由相关部门办理立项、用地、规划、施工、消防等手续。三是给予中央补助资金支持。中央对符合规定的保障性租赁住房建设任务予以补助。四是降低税费负担并执行民用水电气价格。利用非居住存量土地和非居住存量房屋建设保障性租赁住房，取得保障性租赁住房项目认定书后，比照适用住房租赁增值税、房产税等税收优惠政策，用水、用电、用气价格按照居民标准执行。五是进一步加强金融支持。支持银行业金融机构以市场化方式向保障性租赁住房自持主体提供长期贷款，在实施房地产信贷管理时予以差别化对待。
              </p>
              <p>
                <br />
                《意见》要求，各地要做好政策衔接，各有关部门和单位要强化协作，确保各项政策落实到位。
              </p>
            </div>
          </el-col>
          <el-col :span="5" class="right">
            <div class="recommendTitle">推荐</div>
            <div class="recommendList">
              <div class="recommendItem">
                <router-link to="/help1">
                  <img src="../../assets/images/help/help1.jpeg" alt="" />
                  <div class="msg">
                    房屋装修注意事项(菜鸟看后装修无遗憾)--水电安装注意事项
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/help2">
                  <img src="../../assets/images/help/help3.jpeg" alt="" />
                  <div class="msg">租房也可变Dream House</div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help3">
                  <img src="../../assets/images/help/help2.png" alt="" />
                  <div class="msg">
                    房东利益如何最大化之合理装修
                  </div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help4">
                  <img src="../../assets/images/help/help4.png" alt="" />
                  <div class="msg">自建房的房屋租赁税计算</div></router-link
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#information {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/information/资讯背景.png);
    background-size: cover;
  }
  .news {
    .title {
      padding: 12px 0;
      margin-top: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #d2d2d2;
      a {
        color: #5a5e66;
      }
    }
    .content {
      padding-bottom: 50px;
      .left {
        margin-top: 25px;
        .news_title {
          color: #000;
          overflow: hidden;
          .newsDate {
            float: left;
            width: 55px;
            height: 55px;
            background-color: #436993;
            color: #fff;
            padding: 6px 2px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            .year {
              font-size: 12px;
            }
            .day {
              font-size: 28px;
            }
          }
        }
        .newsText {
          height: 55px;
          line-height: 55px;
          padding-left: 70px;
          font-size: 20px;
          background-color: #f1f3f6;
        }
        .news_content {
          text-align: left;
          color: #000;
          .bold {
            font-weight: 700;
            font-size: 18px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: rgb(51, 51, 51);
            text-align: justify;
            line-height: 24px;
          }
        }
      }
      .right {
        margin: 25px 0 0 50px;
        img {
          width: 200px;
          height: 115px;
        }
        background: #f2f6fa;
        min-height: 300px;
        .recommendTitle {
          color: #000;
          text-align: left;
          font-weight: bold;
          padding: 0 10px;
          margin: 20px 0;
          border-left: 3px solid #3b6798;
        }
        .recommendList {
          padding: 0 0 10px;
          .recommendItem {
            padding: 0 10%;
            margin-bottom: 15px;
            .msg {
              color: #717171;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
